// src/context/GlobalContext.js
import { graphql, useStaticQuery } from "gatsby"
import React, { createContext, useState } from "react"

export const GlobalContext = createContext()

export const GlobalProvider = ({ children }) => {


    const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                title
                description
                author
                keywords
                siteUrl
                image
                siteName
              }
            }
          }
        `
      )

  return (
    <GlobalContext.Provider value={[site]}>
      {children}
    </GlobalContext.Provider>
  )
}
